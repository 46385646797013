/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper, Plyr, gsap) => {


		/*
		|
		| Constants
		|-----------
		*/
		const $body = $('body')

		$body.on('loaderEnd', () => console.log('ended'))
		document.querySelectorAll('[data-src], [data-bg]').forEach((item) => app.lazyLoader.observe(item))


		/*
		|
		| Scroll menu fixed
		|-----------------
		*/
		var prevScrollpos = window.pageYOffset;
		window.onscroll = function () {
			var currentScrollpos = window.pageYOffset;
			if (prevScrollpos > currentScrollpos) {
				document.getElementById("header").style.top = "0";
			} else {
				document.getElementById("header").style.top = "-130px";
			}
			prevScrollpos = currentScrollpos;

			if (currentScrollpos < 50) {
				document.getElementById("header").style.top = "0";
			}
		}

		/*
		|
		| Bouton acces-client
		|-----------
		*/
		jQuery(document).ready(function ($) {
			jQuery('.acces-site').on('click', function () {
				jQuery('.closebtn').fadeIn(400);
				jQuery('#block-nossites').addClass('active');
				jQuery('#slider-left').addClass('active');
			});
			jQuery('.closebtn').on('click', function () {
				jQuery('.closebtn').fadeOut(400);
				jQuery('#block-nossites').removeClass('active');
				jQuery('#slider-left').removeClass('active');
			});
			jQuery('#slider-left').before().on('click', function () {
				jQuery('.closebtn').fadeOut(400);
				jQuery('#block-nossites').removeClass('active');
				jQuery('#slider-left').removeClass('active');
			});
		});


		/*
		|
		| Chiffres
		|-----------------
		*/
		$('.key-figures').each(function () {
			const block = {
				wrapper: $(this),
				numbers: $(this).find('.number-counter'),
			};
			if (block.numbers.length) {
				block.timeline = gsap.timeline({
					scrollTrigger: {
						trigger: block.wrapper,
						start: 'top 80%'
					}
				});
				block.numbers.toArray().forEach((number) => {
					let counter = $(number);
					let count = counter.data('count').toString().replace(',', '.').replace(' ', '');
					let zero = { val: 0 };
					let decimals = count.split('.')[1];
					let toFixed = decimals ? decimals.length : false;
					count = toFixed ? parseFloat(count) : parseInt(count);
					block.timeline.to(zero, {
						duration: 1,
						val: count,
						ease: 'power2.out',
						onUpdate: function () {
							let val = 0;
							if (toFixed) {
								let parts = zero.val.toFixed(toFixed).toString().split('.');
								parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
								val = parts.join(',')
							} else {
								val = Math.round(zero.val).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
							}
							counter.text(val);
						}
					}, '-=.1');
				});
			}
		});

		/*
		| 
		| Slider intro
		|-----------
		*/
		$(".swiper-intro").each(function (i) {
			var mySwiperswiperintro = new Swiper($(this)[0], {
				loop: true,
				speed: 800,
				effect: "fade",
				autoplay: {
					delay: 1400,
					disableOnInteraction: false,
				}
			});
		});

		/*
		| 
		| Slider témoignage
		|-----------
		*/
		$(".swiper-temoignage").each(function (i) {
			var mySwiper = new Swiper($(this)[0], {
				loop: true,
				slidesPerView: 1,
				speed: 1000,
				spaceBetween: 40,
				navigation: {
					nextEl: $(".button-next")[i],
					prevEl: $(".button-prev")[i],
				}
			});
		});

		/*
		| 
		| Slider services
		|-----------
		*/
		$(".swiper-services").each(function (i) {
			var mySwiper = new Swiper($(this)[0], {
				mousewheel: {
					forceToAxis: true,
					invert: true
				},
				speed: 1000,
				slidesPerView: 2.5,
				spaceBetween: 40,
				centeredSlides: false,
				breakpoints: {
					0: {
						slidesPerView: 1.2
					},
					768: {
						slidesPerView: 1.2,
						centeredSlides: true,
					},
					992: {
						slidesPerView: 2.5
					},
					1200: {
						slidesPerView: 2.5,
						centeredSlides: false,
					},
				},
				navigation: {
					nextEl: $(".button-next")[i],
					prevEl: $(".button-prev")[i],
				}
			});
		});

		/*
		| 
		| Slider history
		|-----------
		*/

		var SwiperHistory = new Swiper(".swiper-history", {
			speed: 1000,
			slidesPerView: 2.5,
			spaceBetween: 80,
			centeredSlides: true,
			breakpoints: {
				0: {
					slidesPerView: 1.2
				},
				768: {
					slidesPerView: 1.2,
				},
				992: {
					slidesPerView: 1.5,
					spaceBetween: 20
				},
				1200: {
					slidesPerView: 2.5,
					spaceBetween: 80
				},
			},
			pagination: {
				el: ".elem-date-pagination",
				clickable: true,
				renderBullet: function (index, className) {
					var names = [];
					$(".swiper-wrapper .swiper-slide").each(function (index) {
						names.push($(this).data("date"));
					});
					return '<span class="' + className + '">' + names[index] + "</span>";
				},
			},

			navigation: {
				nextEl: ".button-next",
				prevEl: ".button-prev",
			}

		});

		/*
		| 
		| Slider history progress bar
		|-----------
		*/
		function percent() {
			let percent = ((SwiperHistory.activeIndex + 1) / SwiperHistory.slides.length) * 100 + "%";
			jQuery('.progressbar').css("width", percent);
		}

		percent();

		SwiperHistory.on('slideChange', function () {
			percent();
		});


		/*
		| 
		| Slider expemple rea
		|-----------
		*/

		$(".swiper-exp-rea").each(function (i) {
			if ($(this).find(".swiper-slide").length > 1) {
				var mySwiper = new Swiper($(this)[0], {
					loop: true,
					slidesPerView: 1,
					watchOverflow: true,
					speed: 1000,
					spaceBetween: 40,
					navigation: {
						nextEl: $(".button-next-exp")[i],
						prevEl: $(".button-prev-exp")[i],
					}
				});
			} else {
				$(this).find('.swiper-buttons').hide();
			}
		});


		/*
		| 
		| Slider gallery
		|-----------
		*/
		$(".swiper-media").each(function (i) {
			if ($(this).find(".swiper-slide").length > 1) {
				let swiperMedia = new Swiper($(this)[0], {
					speed: 1000,
					watchOverflow: true,
					autoplay: {
						delay: 4000,
						disableOnInteraction: false,
					},
					pagination: {
						el: '.slider__pagination',
						clickable: true,
					}
				});
			} else {
				$(this).find('.slider__controls').hide();
			}
		});



		/*
		| 
		| Slider exemple rea tabs
		|-----------
		*/
		$('#block-exp-rea .tabgroup > div').hide();
		$('#block-exp-rea .tabgroup > div:first-of-type').show();
		$('#block-exp-rea .tabs a').click(function (e) {
			e.preventDefault();
			let $this = $(this),
				tabgroup = '#' + $this.parents('.tabs').data('tabgroup'),
				others = $this.closest('li').siblings().children('a'),
				target = $this.attr('href');
			others.removeClass('active');
			$this.addClass('active');
			$(tabgroup).children('div').hide();
			$(target).show();

		})


		/*
		| 
		| Home liste animes
		|-----------
		*/
		jQuery(".elem-list").on("mouseover", function () {

			jQuery(".elem-list").removeClass('active');
			jQuery(this).addClass('active');

			let dataimg = $(this).data('img');
			jQuery($body).find('.img-list').css("background-image", "url(" + dataimg + ")");
		});

		/*
		| 
		| Home image list
		|-----------
		*/
		jQuery("#block-text-list .block-text li").on("mouseover", function () {
			jQuery("#block-text-list .block-text li").removeClass('active');
			jQuery(this).addClass('active');
		});

		/*
		| 
		| Home filiales
		|-----------
		*/
		jQuery('#block-filiale .tabs .tab-links a').on('click', function (e) {
			var currentAttrValue = jQuery(this).attr('href');
			jQuery('.tabs ' + currentAttrValue).slideDown(400).siblings().slideUp(400);
			jQuery(this).parent('li').addClass('active').siblings().removeClass('active');
			e.preventDefault();
		});

		/*
		|
		| Plyr
		|-----------------
		*/
		const $customPlyrVideo = jQuery('.custom-plyr-video');
		$.each($customPlyrVideo, function () {
			const $video = $(this);
			const $videoPoster = $video.find('.item-poster');
			const $videoIcon = $video.find('.item-play-icon');
			const videoId = $video.data('id');
			const type = $video.data('type');
			let player = new Plyr(`#${videoId}`);
			$(`#${videoId}`).addClass('custom-plyr-video');

			player.on("play", (event) => {
				var delayInMilliseconds = 500;
				setTimeout(function () {
					jQuery('.item-poster').css('opacity', '0');
				}, delayInMilliseconds);
			});
		});

		/*
		| Modal actu
		|-----------------
		*/
		if (jQuery(".iziModal").length) {
			jQuery(".iziModal").iziModal({
				headerColor: "#011A24",
				bodyOverflow: true,
			});
			$body.on("click", ".icon-close", function (event) {
				jQuery(".iziModal").iziModal("close");
			});
		}

	}
}
