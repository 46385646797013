/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app,) => {
        /*
        |
        | Constants
        |------------
        */
        const
            $contactForm = $('.contact-form')
            ;


        /*
        |
        | Tabs forms
        |----------------
        |
        */
        $('#tabs-nav li:first-child').addClass('active');
        $('.tab-content').hide();
        $('.tab-content:first').show();

        $('#tabs-nav li').click(function () {
            $('#tabs-nav li').removeClass('active');
            $(this).addClass('active');
            $('.tab-content').hide();

            var activeTab = $(this).find('a').attr('href');
            $(activeTab).fadeIn();
            return false;
        });



        $(document).ready(function () {
            let hash = window.location.hash.substring(1);

            if (hash) {
                $('#tabs-nav li').removeClass('active');
                $('.tab-content').hide();

                $('.' + hash).addClass('active');
                $('#' + hash).show();
            }
        });

        /*
        |
        | Custom file field
        |------------
        */
        var elem = jQuery(".ginput_container_fileupload");
        elem.attr('data-content', '');

        jQuery("input:file").change(function () {

            var filename = this.files[0].name;

            if (jQuery('input[type="file"]').val()) {
                elem.css('background-color', '#eff5ef');
                elem.css('border-color', '#578357');
                elem.attr('data-content', filename);
                elem.addClass('active');
            }
        });


    }
}
