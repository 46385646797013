/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs
|------------------
*/
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';
import gsap from 'gsap';
import SplitText from '@lib/gsap-pro/SplitText';
import ScrollTo from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';
require('@lib/iziModal/js/iziModal.js')($);

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollTo);
Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

/*
|
| Importing Components
|-----------------------
*/
import customGoogleMap from '@components/custom-google-map';
import MaterializeForm from '@components/materialize-form';
import Kira from '@components/kira';
import Menu from '@components/menu';
import Plyr from '@components/plyr.min.js';

/*
|
| Importing Utils
|-------------------
*/
import * as app from '@utils/global';
import Router from '@utils/router';
import Ajax from '@utils/ajax';

/*
|
| Importing App files
|----------------------
*/
import main from '@pages/main';
import news from '@pages/news';
import animations from '@pages/animations';
import contact from '@pages/contact';

/*
|
| Routing
|----------
*/
const routes = new Router([
  {
    'file': animations,
    'dependencies': [app, gsap, ScrollTrigger, SplitText, Menu, Kira]
  },
  {
    'file': main,
    'dependencies': [app, Swiper, Plyr, gsap]
  },
  {
    'file': news,
    'dependencies': [app, gsap, Ajax],
    'resolve': '#page-news-archive'
  },
  {
    'file': contact,
    'dependencies': [app],
    'resolve': '#page-contact'
  }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

