/*** IMPORTS FROM imports-loader ***/
var define = false;

/*-------- Multilingual --------*/

// GET LANG
export const getLang = () => document.documentElement.hasAttribute('lang') ? document.documentElement.lang.split('-')[0] : 'fr'


/*-------- Utils --------*/

// HOVER ENABLED
export const hoverEnabled = () => window.matchMedia('(hover: hover)').matches

// MOTION ENABLED
export const motionEnabled = () => window.matchMedia('(prefers-reduced-motion: no-preference)').matches

// DUMP
export const dump = (value) => console.log(value)

// DISPATCH EVENT
export const dispachEvent = (el, name, data = {}) => el.dispatchEvent(new CustomEvent(name, { detail : data }))

// IS ELEMENT DEFINED
export const isDefined = (item) => typeof item !== 'undefined'

// ELEMENT EXISTS
export const exist = (element) => element.length

// DEBOUNCE
export const debounce = (func, wait, immediate = false) => {
    let timeout
    
    return function executedFunction() {
        let context = this
        let args = arguments

        let later = function() {
            timeout = null
            if(!immediate) func.apply(context, args)
        }

        let callNow = immediate && !timeout

        clearTimeout(timeout)

        timeout = setTimeout(later, wait)

        if(callNow) func.apply(context, args)
    }
}


/*-------- Cookies --------*/

// GET
export const getCookie = (cname) => {
    var name = cname + "="
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(';')
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
        }
    }

    return null
}

// SET
export const setCookie = (name, value, nbDays) => {
    const date = new Date()
    date.setTime(date.getTime() + (nbDays * 24 * 60 * 60 * 1000))
    const expires = "expires=" + date.toUTCString()
    document.cookie = name + "=" + value + ";" + expires + ";path=/"
}

// DELETE
export const deleteCookie = (name) => document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'


/*-------- Lazy loader --------*/
export const lazyLoader = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
        if(!entry.isIntersecting) return

        if(entry.target.hasAttribute('data-src')) {
            entry.target.src = entry.target.dataset.src
            entry.target.removeAttribute('data-src')
        }
        
        if(entry.target.hasAttribute('data-bg')) {
            entry.target.style.backgroundImage = `url(${entry.target.dataset.bg})`
            entry.target.removeAttribute('data-bg')
        }

        observer.unobserve(entry.target)
    })
})
